<template>
  <transition name="slide">
    <CCard>
      <h3 style="margin-left: 2rem; margin-top: 2rem">
        {{ integration.name + " integration" }}
      </h3>
      <CAlert :color="alertType" v-if="message"> {{ message }}</CAlert>

      <hr />
      <CCardBody>
        <div>
          <h3 style="margin-top: 20px !important">
            <CIcon name="cilSettings" /> Generic Fields Configuration
            <hr />
          </h3>
          <CRow>
            <CCol sm="6">
              <CInput label="User Name" type="text" v-model="integration.userName"></CInput>
            </CCol>
            <CCol sm="6">
              <CInput label="Password" type="text" v-model="integration.password"></CInput>
            </CCol>
          </CRow>
          <h3 style="margin-top: 20px !important">
            <CIcon name="cilSettings" /> Custom Headers Configuration
            <hr />
          </h3>
          <CRow v-if="headers.length >= 1">
            <CCol sm="6" v-for="(header, index) in headers" :key="index">
              <label :for="'header-' + header.headerName">{{
                header.headerName
              }}</label>
              <CInput type="text" :id="'header-' + header.headerName" v-model="header.headerValue" />
            </CCol>
          </CRow>
          <h3 style="margin-top: 20px !important">
            <CIcon name="cilSettings" /> Config fields Configuration
            <hr />
          </h3>
          <CRow v-if="configFields.length >= 1">
            <CCol sm="6" v-for="(config, index) in configFields" :key="index">
              <label :for="'config-' + config.headerName">{{
                config.headerName
              }}</label>
              <CInput type="text" :id="'config-' + config.headerName" v-model="config.headerValue" />
            </CCol>
          </CRow>
          <h3 style="margin-top: 20px !important">
            <CIcon name="cilSettings" /> Custom fields configuration
            <hr />
          </h3>
          <CRow class="settings">
            <form-builder ref="formBuilder" type="template" v-model="configuration"></form-builder>
          </CRow>
          <CRow>
            <CCol col="9"></CCol>
            <CCol class="d-flex justify-content-end">
              <CButton color="secondary" @click="goBack" style="margin-right: 15px">
                <CIcon name="cil-list" /> Back to the list
              </CButton>
              <CButton color="primary" @click="create(true)">
                <CIcon name="cil-save" /> Save
              </CButton>
            </CCol>
          </CRow>
        </div>
        <!-- <CSpinner
          style="display: block; position: fixed; top: 50%; left: 50%"
          v-if="isLoadingRequired === true"
          color="primary"
        /> -->
      </CCardBody>
    </CCard>
  </transition>
</template>
<script>
import axios from "axios";
import { FormBuilder } from "v-form-builder";
export default {
  components: { FormBuilder },
  name: "CreateOrUpdateIntegration",
  data: () => {
    return {
      emptyGuid: "00000000-0000-0000-0000-000000000000",
      method: "Create New Integration",
      headers: [],
      customHeadersNames: [],
      configFields: [],
      configFieldsNames: [],
      configFieldsValues: [],
      headerCount: 0,
      configFieldsCount: 0,
      configuration: {},
      formData: null,
      customHeaderValues: [],
      isLoadingRequired: false,
      configuration: {},
      integration: {
        id: "00000000-0000-0000-0000-000000000000",
        name: null,
        userName: null,
        password: null,
      },
      // Alert
      alertType: "success",
      message: null,
    };
  },
  methods: {
    goBack() {
      this.$router.go(-1);
    },
    async create(mustCreate) {
      if (mustCreate) {
        let self = this;
        let form = new FormData();
        form.append("id", self.integration.id);
        form.append("name", self.integration.name);
        form.append("userName", self.integration.userName);
        form.append("password", self.integration.password);
        form.append(
          "serializedFormFields",
          JSON.stringify(this.configuration.controls)
        );
        form.append("allGeneratedForm", JSON.stringify(this.configuration));

        self.customHeadersNames = self.headers.map((header, index) => ({
          headerName: header.headerName,
          headerValue: header.headerValue,
        }));

        form.append(
          "customHeadersNamesJson",
          JSON.stringify(self.customHeadersNames)
        );


        self.configFieldsNames = self.configFields.map((header, index) => ({
          headerName: header.headerName,
          headerValue: header.headerValue,
        }));

        form.append(
          "customConfigNamesJson",
          JSON.stringify(self.configFieldsNames)
        );



        try {
          const response = await axios.post(
            this.$apiAdress + "/v1/PartnerIntegration/" + self.$route.params.id,
            form
          );

          self.integration.id = response.data;
                    self.alertType = "success";
          setTimeout(function () {
              self.message = null;
          }, 5000);
          self.message = "Successfully updated integration.";
        } catch (error) {
                    self.alertType = "danger";
          setTimeout(function () {
              self.message = null;
          }, 10000);
          self.message = error;
        }
      }
    },
    sleep(milliseconds) {
      return new Promise((resolve) => setTimeout(resolve, milliseconds));
    },
    async getIntegration(id) {
      let self = this;
      self.isLoadingRequired = true;

      try {
        const [integrationRes, customHeadersRes] = await Promise.all([
          axios.get(
            this.$apiAdress + "/v1/PartnerIntegration/" + id + "/integration"
          ),
          axios.get(this.$apiAdress + "/v1/PartnerIntegration/" + id),
        ]);

        self.integration = integrationRes.data;
        self.configuration = JSON.parse(integrationRes.data.allGeneratedForm);
        self.headers = integrationRes.data.customHeaders;
        self.configFields = integrationRes.data.configFields;
        debugger;
        customHeadersRes.data.customHeaders.forEach((item, index) => {
          self.customHeaderValues[index] = item.headerValue;
          self.headerCount = self.headerCount + 1;
        });

        customHeadersRes.data.configFields.forEach((item, index) => {
          self.configFieldsValues[index] = item.headerValue;
          self.configFieldsCount = self.configFieldsCount + 1;
        });


        debugger;
        self.headers.forEach((item, index) => {
          var interestIndex = customHeadersRes.data.customHeaders.findIndex(i => i.headerName == item.headerName);
          debugger;
          if (customHeadersRes.data.customHeaders[interestIndex] != null) {
            item.headerValue = customHeadersRes.data.customHeaders[interestIndex].headerValue;
          }
        })


        self.configFields.forEach((item, index) => {
          var interestIndex = customHeadersRes.data.configFields.findIndex(i => i.headerName == item.headerName);
          debugger;
          if (customHeadersRes.data.configFields[interestIndex] != null) {
            item.headerValue = customHeadersRes.data.configFields[interestIndex].headerValue;
          }
        })



        self.integration.userName = customHeadersRes.data.userName;
        self.integration.password = customHeadersRes.data.password;
      } catch (error) {
        self.message = error;
      } finally {
        self.isLoadingRequired = false;
      }
    },
  },
  mounted: function () {
    let self = this;
    // Edit OR Create
    self.getIntegration(self.$route.params.id);
    const style = document.createElement("style");
    style.innerHTML = `
    .settings .vue-form-builder .add-control-container, .settings .vue-form-builder .add-row-container {
      visibility: hidden !important;
    }
    .settings .vue-form-builder .add-section-container {
      visibility: hidden !important;
    }
    .settings .form-configuration-block.pbottom-10 {
      visibility: hidden !important;
    }
    .settings .section-config {
      visibility: hidden !important;
    }
    .settings .option-control[title="Delete Control"],
  .settings .option-control[title="Re-position / Drag"] {
    display: none !important;
  }
  `;
    document.head.appendChild(style);
  },
};
</script>
<style>
.vue-form-builder .copyright-text {
  display: none;
}
</style>
